import Styles from "./Header.module.css"
// import React, { useState, useEffect, useRef } from 'react'
// import WAVES from 'vanta/dist/vanta.waves.min.js'
import HeaderImage from "../../assets/headerimage.webp"
import HeaderImageMobile from "../../assets/headermobile.png"

// import modelPhoto from "../../assets/model_photo.png"

export default function Header(){
    // const [vantaEffect, setVantaEffect] = useState(null)
    // const myRef = useRef(null)
    // useEffect(() => {
    //     if (!vantaEffect) {
    //     setVantaEffect(WAVES({
    //         el: myRef.current,
    //         mouseControls: true,
    //         touchControls: true,
    //         gyroControls: false,
    //         minHeight: 200.00,
    //         minWidth: 200.00,
    //         scale: 1.00,
    //         scaleMobile: 1.00,
    //         color: 0x0b1324
    //     }))
    //     }
    //     return () => {
    //     if (vantaEffect) vantaEffect.destroy()
    //     }
    // }, [vantaEffect])

    return(
        <div className={Styles.header}>
            <div className={Styles.header_tag}>
                <h3 className={Styles.header_tag_h3}>Start</h3>
                <h2 className={Styles.header_tag_h2}>20-May</h2>
            </div>
            <div className={Styles.header_blur}></div>
            <div className={Styles.header_container}>
                <h1 className={Styles.header_h1}>MediaXpert</h1>
                <div className={Styles.header_subtitle_container}>
                    <p className={Styles.header_p}>O'zini oqlaydigan kurs!</p>
                </div>
            </div>
            <img src={HeaderImage} alt="founderimage" className={Styles.header_image}/>
            <img src={HeaderImageMobile} alt="founderimage" className={Styles.header_image_mobile}/>
            <div className={Styles.header_gradient}>
                <div className={Styles.gradient_container}>
                    <h2 className={Styles.gradient_founder_title}>Halida<br/>Abduraxmanova</h2>
                    <div className={Styles.gradient_flex}>
                        <div className={Styles.gradient_flex_item}><b>200+</b> o’quvchilar</div>
                        <div className={Styles.gradient_flex_separator}></div>
                        <div className={Styles.gradient_flex_item}>O’quvchilarim <b>500$ dan 2000$</b> gacha daromad qilishadi</div>
                    </div>
                </div>
            </div>
            {/* <a href="#pricing" className={Styles.header_button}>
                Batafsil
            </a> */}
         </div>
    )
}