const firebaseConfig = {
    apiKey: "AIzaSyBNEN5lE4rnAr4qjtJ71ZArwtgMbzzKG2s",
    authDomain: "halida-daf67.firebaseapp.com",
    databaseURL: "https://halida-daf67-default-rtdb.firebaseio.com",
    projectId: "halida-daf67",
    storageBucket: "halida-daf67.appspot.com",
    messagingSenderId: "37259275544",
    appId: "1:37259275544:web:20a441360dafca543a826d",
    measurementId: "G-Y9SCJ57EK3"
  };
  
  export default firebaseConfig;